import { HTTP } from "../api";
import { GET_LEDGERLIST_FAIL, GET_LEDGERLIST_REQUSET, GET_LEDGERLIST_SUCCESS, POST_LEDGERLIST_FAIL, POST_LEDGERLIST_REQUSET, POST_LEDGERLIST_SUCCESS } from "../constants/ledgerListModuleConstant";


export const getLedgerModuleList = (page, perPage,isPaginated,search) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_LEDGERLIST_REQUSET });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/invoice/ledger-entry/?q=${search}&page=${page}&per_page=${perPage}&non_paginated=${isPaginated}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GET_LEDGERLIST_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: GET_LEDGERLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const postLedgerModuleList = (payload) => async (
  dispatch,
  getState
) => {
  dispatch({ type: POST_LEDGERLIST_REQUSET, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(
      `/invoice/ledger-entry/`, payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: POST_LEDGERLIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    dispatch({
      type: POST_LEDGERLIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};