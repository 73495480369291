export const permission = [
    {
      type: "owner",
      settings: true,
      userModule: true,
      apModule: {
        transactionAddEdit: true,
        invoiceApproveReject: true,
        submitInvoice: true,
      },
      arModule: {
        addEditTransactions: true,
      },
      bankingModule: {
        addEditTransactions: true,
      },
      reports: true,
      remarks: "Owner can add/remove admins",
    },
    {
      type: "admin",
      settings: true,
      userModule: true,
      apModule: {
        transactionAddEdit: true,
        invoiceApproveReject: true,
        submitInvoice: true,
        sendToErp:true,
      },
      arModule: {
        addEditTransactions: true,
      },
      bankingModule: {
        addEditTransactions: true,
      },
      reports: true,
      remarks: "Admin can add/remove non-admins",
    },
    {
      type: "accountant",
      settings: false,
      userModule: false,
      apModule: {
        transactionAddEdit: true,
        invoiceApproveReject: true,
        submitInvoice: true,
      },
      arModule: {
        addEditTransactions: true,
      },
      bankingModule: {
        addEditTransactions: true,
      },
      reports: true,
    },
    {
      type: "expense_manager",
      settings: false,
      userModule: false,
      apModule: {
        transactionAddEdit: true,
        invoiceApproveReject: true,
        submitInvoice: true,
        sendToErp:true,
      },
      arModule: {
        addEditTransactions: false,
      },
      bankingModule: {
        addEditTransactions: false,
      },
      reports: false,
    },
    {
      type: "mis_reporter",
      settings: false,
      userModule: false,
      apModule: {
        transactionAddEdit: false,
        invoiceApproveReject: true,
        submitInvoice: false,
      },
      arModule: {
        addEditTransactions: false,
      },
      bankingModule: {
        addEditTransactions: false,
      },
      reports: true,
    },
    {
      type: "standard_user",
      settings: false,
      userModule: false,
      apModule: {
        transactionAddEdit: false,
        invoiceApproveReject: false,
        submitInvoice: true,
      },
      arModule: {
        addEditTransactions: false,
      },
      bankingModule: {
        addEditTransactions: false,
      },
      reports: true,
    },
    {
      type: "auditor",
      settings: false,
      userModule: false,
      apModule: {
        transactionAddEdit: true,
        invoiceApproveReject: true,
        submitInvoice: false,
      },
      arModule: {
        addEditTransactions: true,
      },
      bankingModule: {
        addEditTransactions: true,
      },
      reports: true,
    },
  ];