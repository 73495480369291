/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import "../_components/antd.css";
import { message, Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";
import {
  getTeamMemberListApi,
  teamMemberDeleteApi,
} from "../redux/actions/UserAction";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getExpenseManagerListApi,
  getRolesListApi,
} from "../redux/actions/commonApiAction";
import {
  TEAM_MEMBER_CREATE_RESET,
  TEAM_MEMBER_DELETE_RESET,
  TEAM_MEMBER_UPDATE_RESET,
} from "../redux/constants/UserConstant";
import UserManagementAddModal from "./userManagementAddModal";
import UserManagementEditModal from "./userManagementEditModal";
import UserManageDeleteModal from "./userManagementDeleteModal";

const schema = yup.object({
  first_name: yup
    .string()
    .required("First Name is required")
    .trim(),
  last_name: yup
    .string()
    .required("Last Name is required")
    .trim(),
  email: yup
    .string()
    .required("Email is required")
    .email("Must be a valid email address")
    .trim(),
  phone: yup
    .string()
    .required("Mobile number is required")
    .trim(),
  role: yup
    .object()
    .shape({
      value: yup.string().required("Role is required"),
      label: yup.string().required("Role label is required"),
    })
    .nullable()
    .required("Role is required"),
});

const userManagement = () => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [addData, setAddData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    manager: null,
    role: null,
  });
  const [editData, setEditData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: null,
  });
  const [deleteData, setDeleteData] = useState({});
  const [apiError, setApiError] = useState({});
  const [deleteError, setDeleteError] = useState("");
  const [editTrigger, setEditTrigger] = useState(false);
  const [page, setPage] = useState({ page: 1, perPage: 10 });

  const [messageApi, contextHolder] = message.useMessage();

  const getTeamMemberList = useSelector((state) => state?.getTeamMemberList);
  const { userList, loading } = getTeamMemberList;

  const getRolesList = useSelector((state) => state?.getRolesList);
  const { rolesData } = getRolesList;

  const getManagerList = useSelector((state) => state.getManagerList);
  const { managerData } = getManagerList;


  console.log(managerData,'managerData');
  
  const teamMemberCreation = useSelector((state) => state?.teamMemberCreation);
  const {
    loading: teamMemberCreateLoading,
    success,
    error,
  } = teamMemberCreation;

  const teamMemberUpdation = useSelector((state) => state?.teamMemberUpdation);
  const {
    loading: teamMemberUpdateLoading,
    success: teamMemberUpdateSuccess,
    error: teamMemberUpdateError,
  } = teamMemberUpdation;

  const teamMemberDeletion = useSelector((state) => state?.teamMemberDeletion);
  const {
    loading: teamMemberDeleteLoading,
    success: teamMemberDeleteSuccess,
    error: teamMemberDeleteError,
  } = teamMemberDeletion;

  const dispatch = useDispatch();

  const selectRefs = useRef({});

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  const onModalClose = () => {
    $("#add_user").on("hide.bs.modal", () => {
      setAddData({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        manager: null,
        role: null,
      });
      setApiError({});
      reset();
    });
    $("#edit_user").on("hide.bs.modal", () => {
      setEditData({
        first_name: "",
        last_name: "",
        email: "",
        role: null,
        manager: null,
        phone: "",
      });
      setApiError({});
      setEditTrigger(false);
      reset();
    });
    $("#delete_user").on("hide.bs.modal", () => {
      setDeleteData({});
      setDeleteError("");
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      render: (text, record, index) =>
        page?.perPage * (page?.page - 1) + index + 1,
    },
    {
      title: "User",
      dataIndex: ["info", "first_name"],
      sorter: (a, b) =>
        a?.info?.first_name?.length - b?.info?.first_name?.length,
      record: (text, record) => (
        <div>{record?.info?.first_name + " " + record?.info?.last_name}</div>
      ),
    },
    {
      title: "Email",
      dataIndex: ["info", "email"],
      sorter: (a, b) => a?.info?.email?.length - b?.email?.length,
    },

    {
      title: "Role Permissions",
      dataIndex: "role",
      sorter: (a, b) => a.role.length - b.role.length,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          <div className="table-invoice align-items-center">
            <Link
              className="btn btn-primary me-2"
              data-bs-toggle="modal"
              to="#edit_user"
              role="button"
              onClick={() => handleEditClick(record)}
            >
              <i className="far fa-edit" />
            </Link>

            <Link
              className="btn btn-greys"
              data-bs-toggle="modal"
              to="#delete_user"
              role="button"
              onClick={() => handleDeleteClick(record)}
            >
              <i className="far fa-trash-o" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleTableChange = (page, per_page) => {
    setPage({ page: page, perPage: per_page });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAddData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
    const editId = id?.split("-")[1];
    setApiError((prevState) => ({ ...prevState, [editId]: "" }));
  };

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: editTrigger ? editData : addData,
  });

  const handleRolesChange = (selectedOptions, id) => {
    const editId = id?.split("-")[1];
    if (editId === "role" || editId === "manager") {
      setEditData((prevState) => ({ ...prevState, [editId]: selectedOptions }));
      setValue(id, selectedOptions); // Set the form value
      trigger(id);
    } else {
      setAddData((prevState) => ({ ...prevState, [id]: selectedOptions }));
      setValue(id, selectedOptions); // Set the form value
      trigger(id);
    }
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleDeleteSubmit = (event) => {
    event.preventDefault();
    dispatch(teamMemberDeleteApi(deleteData?.id));
  };

  
  useEffect(() => {
    if (editData) {
      Object.keys(editData).forEach((field) => {
        setValue(field, editData[field]);
      });
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  useEffect(() => {
    if (error) {
      setApiError(error);
    } else if (teamMemberUpdateError) {
      setApiError(teamMemberUpdateError);
    } else if (teamMemberDeleteError) {
      setDeleteError(teamMemberDeleteError);
    }
  }, [error, teamMemberUpdateError, teamMemberDeleteError]);

  useEffect(() => {
    if (success) {
      setAddData({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        manager: null,
        role: null,
      });
      setApiError({});
      setEditTrigger(false);
      dispatch({ type: TEAM_MEMBER_CREATE_RESET });
      dispatch(getTeamMemberListApi(page?.page, page?.perPage));
      dispatch(getExpenseManagerListApi());
      handleModalClose("#add_user");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Team Member Created Successfully",
        duration: 5, 
      });
    }
  }, [success]);

  useEffect(() => {
    if (teamMemberUpdateSuccess) {
      setEditData({});
      setApiError({});
      dispatch({ type: TEAM_MEMBER_UPDATE_RESET });
      dispatch(getTeamMemberListApi(page?.page, page?.perPage));
      dispatch(getExpenseManagerListApi());
      handleModalClose("#edit_user");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Team Member Updated Successfully",
        duration: 5, 
      });
    }
  }, [teamMemberUpdateSuccess]);

  useEffect(() => {
    if (teamMemberDeleteSuccess) {
      setDeleteData({});
      dispatch({ type: TEAM_MEMBER_DELETE_RESET });
      dispatch(getTeamMemberListApi(page?.page, page?.perPage));
      dispatch(getExpenseManagerListApi());
      handleModalClose("#delete_user");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Team Member Deleted Successfully",
        duration: 5, 
      });
    }
  }, [teamMemberDeleteSuccess]);

  useEffect(() => {
    dispatch(getTeamMemberListApi(page?.page, page?.perPage));
  }, [page?.page, page?.perPage]);

  useEffect(() => {
    dispatch(getRolesListApi());
    dispatch(getExpenseManagerListApi());
  }, []);

  useEffect(() => {
    onModalClose();
  }, []);

  useEffect(() => {
    const fieldOrder = ["first_name", "last_name", "email", "phone", "role"];
    const firstErrorField = fieldOrder.find((field) => errors[field]);

    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "role") {
        if (editTrigger) {
          inputElement = selectRefs.current[`edit-${firstErrorField}`];
        } else {
          inputElement = selectRefs.current[firstErrorField];
        }
      } else {
        if (editTrigger) {
          inputElement = document.getElementById(`edit-${firstErrorField}`);
        } else {
          inputElement = document.getElementById(firstErrorField);
        }
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors, editTrigger]);

  useEffect(() => {
    const fieldOrder = [
      "first_name",
      "last_name",
      "email",
      "phone",
      "manager",
      "role",
    ];
    const firstErrorField = fieldOrder.find(
      (field) => apiError && apiError[field]
    );

    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "role" || firstErrorField === "manager") {
        if (editTrigger) {
          inputElement = selectRefs.current[`edit-${firstErrorField}`];
        } else {
          inputElement = selectRefs.current[firstErrorField];
        }
      } else {
        if (editTrigger) {
          inputElement = document.getElementById(`edit-${firstErrorField}`);
        } else {
          inputElement = document.getElementById(firstErrorField);
        }
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [apiError, editTrigger]);

  const handleEditClick = (record) => {
    const filterData = rolesData?.find((el) => el?.value === record?.role);
    console.log(record,'recor')
    console.log(managerData,'managerData');
    ;
    
    const managerFilterData =
      managerData?.results?.length &&
      managerData?.results?.find((el) => el?.info?.email === record?.manager?.email);

    setEditData({
      id: record?.id,
      first_name: record?.info?.first_name,
      last_name: record?.info?.last_name,
      email: record?.info?.email,
      phone: record?.info?.phone,
      manager: managerFilterData?.info?.first_name
        ? {
            label: `${managerFilterData?.info?.first_name} ${managerFilterData?.info?.last_name}`,
            value: managerFilterData?.id,
          }
        : null,
      role: { label: filterData?.name, value: filterData?.value },
    });
    setEditTrigger(true);
  };

  const handleDeleteClick = (record) => {
    setDeleteData(record);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <div className="col">
                  <h5>Settings - User Management</h5>
                </div>
                <div className="list-btn">
                  <ul className="filter-list filterbutton">
                    <li>
                      <Link
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        to="#add_user"
                        role="button"
                      >
                        <i
                          className="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        />
                        Add User
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body">
                    <div className="table-responsive table-hover cmb-35">
                      <Table
                        className="table usermanagementTable"
                        loading={loading}
                        pagination={{
                          total: userList?.length,
                          current: page?.page,
                          pageSize: page?.perPage,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                          onChange: handleTableChange,
                        }}
                        columns={columns}
                        dataSource={userList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserManagementAddModal
          show={show}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleRolesChange={handleRolesChange}
          rolesData={rolesData}
          managerData={managerData?.results}
          addData={addData}
          teamMemberCreateLoading={teamMemberCreateLoading}
          apiError={apiError}
          errors={errors}
          Controller={Controller}
          control={control}
          selectRefs={selectRefs}
          dispatch={dispatch}
        />

        <UserManagementEditModal
          show={show}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleRolesChange={handleRolesChange}
          rolesData={rolesData}
          managerData={managerData}
          editData={editData}
          teamMemberUpdateLoading={teamMemberUpdateLoading}
          apiError={apiError}
          errors={errors}
          Controller={Controller}
          control={control}
          selectRefs={selectRefs}
          dispatch={dispatch}
        />

        <UserManageDeleteModal
          show={show}
          deleteData={deleteData}
          handleDeleteSubmit={handleDeleteSubmit}
          teamMemberDeleteLoading={teamMemberDeleteLoading}
          teamMemberDeleteError={deleteError}
        />
        {/* sample modal content */}
      </div>
    </>
  );
};
export default userManagement;
