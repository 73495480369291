import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../../_components/paginationfunction";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";

import { getUserActivityModuleList } from "../../redux/actions/UserAction";

const UserLogs = () => {
  const [menu, setMenu] = useState(false);
  const [page, setPage] = useState({ page: 1, perPage: 50 });

  const dispatch = useDispatch();

  const userActivityList = useSelector((state) => state?.userActivityList);
  const { loading, data } = userActivityList;
  // const [show, setShow] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      // render: (text, record) => (
      //   <>
      //     <h2 className="table-avatar">
      //       <Link to="/profile" className="avatar avatar-md me-2">
      //       <img
      //         className="avatar-img rounded-circle"
      //         src={record.image}
      //         alt="User Image"
      //       />
      //       </Link>
      //       <Link to="/profile">
      //       {record.company} <span>{record.email}</span>
      //       </Link>
      //     </h2>
      //   </>
      // ),
      sorter: (a, b) => a.user.length - b.user.length,
    },

    // {
    //   title: "Login IP",
    //   dataIndex: "login_ip",
    //   sorter: (a, b) => a.login_ip.length - b.login_ip.length,
    // },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "TimeStamp",
      dataIndex: "timestamp",
      sorter: (a, b) => a.timestamp.length - b.timestamp.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      sorter: (a, b) => a.action.length - b.action.length,
    },
  ];

  const handleTableChange = (page, pageSize) => {
    setPage({ page: page, perPage: pageSize });
  };

  useEffect(() => {
    dispatch(getUserActivityModuleList(page?.page, page?.perPage));
  }, [page?.page, page?.perPage]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <div className="col">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>Reports</a>
                    </li>
                    <li className="breadcrumb-item active">User Logs</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body">
                    <div className="table-responsive table-hover cmb-55">
                      <Table
                        className="table"
                        loading={loading}
                        pagination={{
                          total: data?.count,
                          current: page?.page,
                          pageSize: page?.perPage,
                          onChange: handleTableChange,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={data?.results}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLogs;
