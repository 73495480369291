import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
// import { Modal, Result, Button } from 'antd';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { message } from "antd";
import { withRouter } from 'react-router-dom';
/* eslint-disable react/prop-types */
const Dashboard = (props) => {
  const [menu, setMenu] = useState(false);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [paymentId, setPaymentId] = useState(null);
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();



  useEffect(() => {
console.log(props.history.state,'his')

    // Check if location state exists and has paymentSuccess
    if (location?.state?.paymentSuccess) {
      // setIsModalVisible(true);
      // setPaymentId(location.state.paymentId);

      // Clean up the location state
      const cleanState = { ...location.state };
      delete cleanState.paymentSuccess;
      delete cleanState.paymentId;

      messageApi.open({
        type: 'success',
        content: 'Payment success',
        duration: 5, 
      });
      
      props.history.replace({
        pathname: '/index',
        state: cleanState
      });
    }
  }, [location, history]);

  // const handleModalClose = () => {
  //   setIsModalVisible(false);
  // };
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
     {/* <Modal
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
      >
        <Result
          // icon={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
          status="success"
          title="Payment Successful!"
          subTitle={
            <div>
              <p>Thank you for your subscription. Your payment has been processed successfully.</p>
              {paymentId && <p>Payment ID: {paymentId}</p>}
            </div>
          }
          extra={[
            <Button type="primary" onClick={handleModalClose} key="close">
              Got it!
            </Button>
          ]}
        />
      </Modal> */}
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />

      {contextHolder}
       

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-1">
                        <i className="fas fa-dollar-sign"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Amount Due</div>
                        <div className="dash-counts">
                          <p>1,642</p>
                        </div>
                      </div>
                    </div>
                    <div className="progress progress-sm mt-3">
                      <div
                        className="progress-bar bg-5"
                        role="progressbar"
                        style={{ width: "75%" }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="text-muted mt-3 mb-0">
                      <span className="text-danger me-1">
                        <i className="fas fa-arrow-down me-1"></i>1.15%
                      </span>{" "}
                      since last week
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-2">
                        <i className="fas fa-users"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Customers</div>
                        <div className="dash-counts">
                          <p>3,642</p>
                        </div>
                      </div>
                    </div>
                    <div className="progress progress-sm mt-3">
                      <div
                        className="progress-bar bg-6"
                        role="progressbar"
                        style={{ width: "65%" }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="text-muted mt-3 mb-0">
                      <span className="text-success me-1">
                        <i className="fas fa-arrow-up me-1"></i>2.37%
                      </span>{" "}
                      since last week
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-3">
                        <i className="fas fa-file-alt"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Invoices</div>
                        <div className="dash-counts">
                          <p>1,041</p>
                        </div>
                      </div>
                    </div>
                    <div className="progress progress-sm mt-3">
                      <div
                        className="progress-bar bg-7"
                        role="progressbar"
                        style={{ width: "85%" }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="text-muted mt-3 mb-0">
                      <span className="text-success me-1">
                        <i className="fas fa-arrow-up me-1"></i>3.77%
                      </span>{" "}
                      since last week
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-4">
                        <i className="far fa-file"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Estimates</div>
                        <div className="dash-counts">
                          <p>2,150</p>
                        </div>
                      </div>
                    </div>
                    <div className="progress progress-sm mt-3">
                      <div
                        className="progress-bar bg-8"
                        role="progressbar"
                        style={{ width: "45%" }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="text-muted mt-3 mb-0">
                      <span className="text-danger me-1">
                        <i className="fas fa-arrow-down me-1"></i>8.68%
                      </span>{" "}
                      since last week
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Dashboard);
