/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import {
  getUserActivityModuleList,
  getUserProfile,
} from "../../redux/actions/UserAction";
// import { itemRender } from "../../_components/paginationfunction";

import { Pagination, Spin, message } from "antd";
import { USER_PROFILE_UPDATE_RESET } from "../../redux/constants/UserConstant";
import ProfileUpdate from "./profileUpdate";

export const formatMMDD = (date) => {
  const dateObj = new Date(date);

  // Get the month and day
  const options = { month: "long", day: "numeric" };
  const formattedDate = dateObj.toLocaleDateString("en-US", options);
  return formattedDate;
};

const Profile = () => {
  const [menu, setMenu] = useState(false);
  const [page, setPage] = useState({ page: 1, perPage: 50 });
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileUpdateError, setProfileUpdateError] = useState({});
  const [isChange, setIsChange] = useState(false);
  const dispatch = useDispatch();
  const fileRef = useRef(null);

  const userActivityList = useSelector((state) => state?.userActivityList);
  const { loading, data } = userActivityList;

  const userProfileList = useSelector((state) => state?.getuserProfile);
  const { profileloading, profileData } = userProfileList;

  const userProfileUpdation = useSelector(
    (state) => state?.userProfileUpdation
  );
  const {
    loading: userProfileUpdateLoading,
    success: userProfileUpdateSuccess,
    error: userProfileUpdateError,
  } = userProfileUpdation;

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleTableChange = (page, pageSize) => {
    setPage({ page: page, perPage: pageSize });
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    fileRef.current.value = "";
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const onModalClose = () => {
    $("#profile_update_modal").on("hide.bs.modal", () => {
      dispatch({ type: USER_PROFILE_UPDATE_RESET });
      setIsChange(false);
      setSelectedFile(null);
      setProfileUpdateError({});
      setIsChange(false);
      setIsModalVisible(false);
      fileRef.current.value = "";
      dispatch({ type: USER_PROFILE_UPDATE_RESET });
    });
  };

  useEffect(() => {
    if (userProfileUpdateSuccess) {
      dispatch({ type: USER_PROFILE_UPDATE_RESET });
      setSelectedFile(null);
      setProfileUpdateError({});
      setIsChange(false);
      setIsModalVisible(false);
      handleModalClose("#profile_update_modal");
      closeModal();
      dispatch(getUserProfile());
      messageApi.open({
        type: "success",
        content: "Profile Updated Successfully",
        duration: 5, 
      });
    }
  }, [userProfileUpdateSuccess]);

  useEffect(() => {
    if (userProfileUpdateError) {
      setProfileUpdateError(userProfileUpdateError);
    }
  }, [userProfileUpdateError]);

  useEffect(() => {
    onModalClose();
  }, []);

  useEffect(() => {
    dispatch(getUserActivityModuleList(page?.page, page?.perPage));
    dispatch(getUserProfile());
  }, [page?.page, page?.perPage]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/** Page Wrapper  */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row justify-content-lg-center">
              <div className="col-lg-10">
                {/* Page Header */}
                <div className="page-header">
                  <div className="content-page-header">
                    <h5>Profile</h5>
                  </div>
                </div>
                <br />
                <br />
                <br />
                {/* /Page Header */}
                {/* <div className="profile-cover">
                  <div className="profile-cover-wrap">
                    <img
                      className="profile-cover-img"
                      src={img2}
                      alt="Profile Cover"
                      id="cover-image"
                    />
                    <div className="cover-content">
                      <div className="custom-file-btn">
                        <input
                          type="file"
                          className="custom-file-btn-input"
                          id="cover_upload"
                        />
                        <label
                          className="custom-file-btn-label btn btn-sm btn-white"
                          htmlFor="cover_upload"
                        >
                          <i className="fas fa-camera" />
                          <span className="d-none d-sm-inline-block ms-1">
                            Update Cover
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="profile-cover">
                  <div className="profile-cover-wrap">
                    <img
                      className="profile-cover-img"
                      src="path/to/your/image.jpg" // Replace with your image path
                      alt="Profile Cover"
                      id="cover-image"
                    />
                    <div className="cover-content">
                      <div className="custom-file-btn">
                        <button
                          type="button"
                          className="custom-file-btn-label btn btn-sm btn-white"
                          // onClick={handleOpenModal}
                        >
                          <i className="fas fa-camera" />
                          <span className="d-none d-sm-inline-block ms-1">
                            Update Cover
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

                <ProfileUpdate
                  profileData={profileData}
                  setIsModalVisible={setIsModalVisible}
                  isModalVisible={isModalVisible}
                  setSelectedFile={setSelectedFile}
                  selectedFile={selectedFile}
                  setProfileUpdateError={setProfileUpdateError}
                  profileUpdateError={profileUpdateError}
                  isChange={isChange}
                  setIsChange={setIsChange}
                  userProfileUpdateLoading={userProfileUpdateLoading}
                  fileRef={fileRef}
                />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="card card-body">
                      <h5>Complete your profile</h5>
                      {/* Progress */}
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="progress progress-md flex-grow-1">
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "30%" }}
                            aria-valuenow={30}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="ms-4">30%</span>
                      </div>
                      {/* /Progress */}
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title d-flex justify-content-between">
                          <span>Profile</span>
                          <Link className="btn btn-sm btn-white" to="/settings">
                            Edit
                          </Link>
                        </h5>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled mb-0">
                          <li className="py-0">
                            <h6>About</h6>
                          </li>
                          <li>{profileData?.username}</li>
                          <li>{profileData?.role}</li>
                          {/* <li>Hafner Pvt Ltd.</li> */}
                          <li className="pt-2 pb-0">
                            <h6>Contacts</h6>
                          </li>
                          <li>{profileData?.email}</li>
                          {/* <li>charleshafner@example.com</li> */}
                          {/* <li>+1 (304) 499-13-66</li> */}
                          <li>{profileData?.phone}</li>
                          <li className="pt-2 pb-0">
                            <h6>Glance Email</h6>
                          </li>
                          <li>{profileData?.glance_email}</li>
                          {/* <li className="pt-2 pb-0">
                            <h6>Address</h6>
                          </li>
                          <li>
                            4663 Agriculture Lane,
                            <br />
                            Miami,
                            <br />
                            Florida - 33165,
                            <br />
                            United States.
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="card" style={{marginBottom:0}}>
                      <div className="card-header">
                        <h5 className="card-title">Activity</h5>
                      </div>
                      {loading ? (
                        <Spin />
                      ) : (
                        <div className="card-body card-body-height">
                          <ul className="activity-feed">
                            {data?.results?.map((ele, index) => (
                              <li className="feed-item" key={index}>
                                <div className="feed-date">
                                  {ele?.timestamp
                                    ? formatMMDD(ele?.timestamp)
                                    : ""}
                                </div>
                                <span className="feed-text">
                                  <b>{ele?.description}</b>
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {data?.results?.length > 0 && (
                        <>
                            <Pagination
                              total={data?.count}
                              defaultPageSize={page?.perPage}
                              // showTotal={(total, range) =>
                              //   `Showing ${range[0]} to ${range[1]} of ${total} entries`
                              // }
                              current={page?.page}
                              onChange={handleTableChange}
                              pageSizeOptions={[20, 30, 50, 100]}
                              itemRender={(current, type) => {
                                if (type === "page") {
                                  return (
                                    <span style={{ margin: "0 5px" }}>
                                      {current}
                                    </span>
                                  );
                                }
                                return null; // Hide other elements (prev and next buttons)
                              }}
                            style={{position:"relative", marginTop:"0px !important"}}
                            />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile;
