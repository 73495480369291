import {
  GET_USER_ACTIVITY_FAIL,
  GET_USER_ACTIVITY_REQUEST,
  GET_USER_ACTIVITY_SUCCESS,
  UPDATE_OTP_INFO,
  USER_COMPANY_FAIL,
  USER_COMPANY_REQUEST,
  USER_COMPANY_RESET,
  USER_COMPANY_SUCCESS,
  USER_OTP_FAIL,
  USER_OTP_REQUEST,
  USER_OTP_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  TEAM_MEMBER_CREATE_FAIL,
  TEAM_MEMBER_CREATE_REQUEST,
  TEAM_MEMBER_CREATE_RESET,
  TEAM_MEMBER_CREATE_SUCCESS,
  TEAM_MEMBER_UPDATE_FAIL,
  TEAM_MEMBER_UPDATE_REQUEST,
  TEAM_MEMBER_UPDATE_RESET,
  TEAM_MEMBER_UPDATE_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  TEAM_MEMBER_DELETE_REQUEST,
  TEAM_MEMBER_DELETE_SUCCESS,
  TEAM_MEMBER_DELETE_FAIL,
  TEAM_MEMBER_DELETE_RESET,
  GET_TEAM_MEMBER_LIST_REQUEST,
  GET_TEAM_MEMBER_LIST_SUCCESS,
  GET_TEAM_MEMBER_LIST_FAIL,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  USER_PROFILE_UPDATE_RESET,
  SIDE_MENU_UPDATE,
} from "../constants/UserConstant";
import { permission } from "../permission";

export const userSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case SIDE_MENU_UPDATE:
      return { ...state, currentSideMenu: action.payload };
    case USER_SIGNIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload.data.access,
        refresh: action.payload.data.refresh,
        userName: action.payload.userData.username,
        userId: action.payload.userData.user_id,
        role: action.payload.userData.role,
        permissions: permission?.find(
          (p) => p.type === action?.payload?.userData?.role
        ),
      };
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_SIGNOUT:
      return {};
    case UPDATE_OTP_INFO:
      return {
        ...state,
        userInfo: action.payload.data.access,
        refresh: action.payload.data.refresh,
        userName: action.payload.userData.username,
        userId: action.payload.userData.user_id,
      };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };

    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        success: true,
        userRegister: action.payload.data,
      };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userOTPReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_OTP_REQUEST:
      return { loading: true };

    case USER_OTP_SUCCESS:
      return {
        loading: false,
        payload: action.payload,
        success: true,
      };
    case USER_OTP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userCompanyInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_COMPANY_REQUEST:
      return { loading: true };

    case USER_COMPANY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      };
    case USER_COMPANY_FAIL:
      return { loading: false, error: action.payload };

    case USER_COMPANY_RESET:
      return {};
    default:
      return state;
  }
};

export const userActivityListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_ACTIVITY_REQUEST:
      return { loading: true };

    case GET_USER_ACTIVITY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };

    case GET_USER_ACTIVITY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teamMemberListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TEAM_MEMBER_LIST_REQUEST:
      return { loading: true };

    case GET_TEAM_MEMBER_LIST_SUCCESS:
      return {
        loading: false,
        userList: action.payload,
      };

    case GET_TEAM_MEMBER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teamMemberCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_MEMBER_CREATE_REQUEST:
      return { loading: true };

    case TEAM_MEMBER_CREATE_SUCCESS:
      return { loading: false, success: true };

    case TEAM_MEMBER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_MEMBER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const teamMemberUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_MEMBER_UPDATE_REQUEST:
      return { loading: true };

    case TEAM_MEMBER_UPDATE_SUCCESS:
      return { loading: false, success: true };

    case TEAM_MEMBER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_MEMBER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const teamMemberDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_MEMBER_DELETE_REQUEST:
      return { loading: true };

    case TEAM_MEMBER_DELETE_SUCCESS:
      return { loading: false, success: true };

    case TEAM_MEMBER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_MEMBER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
      return { loading: true };
    case GET_USER_PROFILE_SUCCESS:
      return { profileloading: false, profileData: action.payload };
    case GET_USER_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userProfileUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return { loading: true };

    case USER_PROFILE_UPDATE_SUCCESS:
      return { loading: false, success: true };

    case USER_PROFILE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_PROFILE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
