export const BANK_ADD_REQUEST = "BANK_ADD_REQUEST";
export const BANK_ADD_SUCCESS = "BANK_ADD_SUCCESS";
export const BANK_ADD_FAIL = "BANK_ADD_FAIL";
export const BANK_ADD_RESET = "BANK_ADD_RESET";
export const BANK_GET_REQUEST = "BANK_GET_REQUEST";
export const BANK_GET_SUCCESS = "BANK_GET_SUCCESS";
export const BANK_GET_FAIL = "BANK_GET_FAIL";
export const BANK_GET_RESET = "BANK_GET_RESET";
export const BANKLISTSET_PAGE = "BANKLISTSET_PAGE";

export const BANK_EDIT_DETAIL_REQUEST = "BANK_EDIT_DETAIL_REQUEST";
export const BANK_EDIT_DETAIL_SUCCESS = "BANK_EDIT_DETAIL_SUCCESS";
export const BANK_EDIT_DETAIL_FAIL = "BANK_EDIT_DETAIL_FAIL";
export const BANK_EDIT_REQUEST = "BANK_EDIT_REQUEST";
export const BANK_EDIT_SUCCESS = "BANK_EDIT_SUCCESS";
export const BANK_EDIT_FAIL = "BANK_EDIT_FAIL";
export const BANK_EDIT_RESET = "BANK_EDIT_RESET";

export const BANK_GET_LIST_REQUEST = "BANK_GET_LIST_REQUEST";
export const BANK_GET_LIST_SUCCESS = "BANK_GET_LIST_SUCCESS";
export const BANK_GET_LIST_FAIL = "BANK_GET_LIST_FAIL";
export const BANK_GET_LIST_RESET = "BANK_GET_LIST_RESET";

export const BANK_UPLOAD_REQUEST = "BANK_UPLOAD_REQUEST";
export const BANK_UPLOAD_SUCCESS = "BANK_UPLOAD_SUCCESS";
export const BANK_UPLOAD_FAIL = "BANK_UPLOAD_FAIL";
export const BANK_UPLOAD_RESET = "BANK_UPLOAD_RESET";

export const TRANSACTION_VIEW_REQUEST = "TRANSACTION_VIEW_REQUEST";
export const TRANSACTION_VIEW_SUCCESS = "TRANSACTION_VIEW_SUCCESS";
export const TRANSACTION_VIEW_FAIL = "TRANSACTION_VIEW_FAIL";
export const TRANSACTION_VIEW_RESET = "TRANSACTION_VIEW_RESET";

export const BANK_STATEMENT_VIEW_REQUEST = "BANK_STATEMENT_VIEW_REQUEST";
export const BANK_STATEMENT_VIEW_SUCCESS = "BANK_STATEMENT_SUCCESS";
export const BANK_STATEMENT_FAIL = "BANK_STATEMENT_FAIL"
