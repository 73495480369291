import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";

const GroupCreationModal = ({ visible, onCancel, onOk }) => {
  const [modalType, setModalType] = useState('');
  const userData = useSelector((state) => state.userSignin);
  const [parentGroups, setParentGroups] = useState([])
  const { userInfo } = userData;
  const [formData, setFormData] = useState({
    nameOfGroup: "",
    parentGroup: null,
    isPrimaryGroup: false,
    cashAssets: "",
    fixedAssets: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onOk(formData);
    setFormData({
      nameOfGroup: "",
      parentGroup: null,
      isPrimaryGroup: false,
      cashAssets: "",
      fixedAssets: "",
    });
  };

  // Define options based on isPrimaryGroup
  // const getParentOptions = () => {
  //   if (formData.isPrimaryGroup) {
  //     // Simple list of heads when isPrimaryGroup is true
  //     return [
  //       { value: "equity", label: "Equity" },
  //       { value: "income", label: "Income" },
  //       { value: "expense", label: "Expense" },
  //       { value: "investment", label: "Investment" },
  //     ];
  //   } else {
  //     // Grouped options when isPrimaryGroup is false
  //     return [
  //       { 
  //         label: "Equity",
  //         options: [
  //           { value: "equity_1", label: "Equit" },
  //           // { value: "equity_2", label: "Equity Subheading 2" },
  //         ]
  //       },
  //       {
  //         label: "Income",
  //         options: [
  //           { value: "income_1", label: "Income" },
  //           { value: "income_2", label: "Other Income" },
  //         ]
  //       },
  //       {
  //         label: "Expense",
  //         options: [
  //           { value: "expense_1", label: "Expense" },
  //           // { value: "expense_2", label: "Expense Subheading 2" },
  //         ]
  //       },
  //       {
  //         label: "Investment",
  //         options: [
  //           { value: "investment_1", label: "Investment " },
  //           { value: "investment_2", label: "Other Investment" },
  //         ]
  //       }
  //     ];
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.tideiq.com/erp/linked/`, {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      });
    
      console.log(response.data.erp, 'x');
      setModalType(response.data.erp || 'tally')
      return response.data; 
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };


  const fetchParent = async () => {
    try {
      const response = await axios.get(`https://api.tideiq.com/ledger/ledger-group/heads/`, {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      });
    
      console.log(response.data, 'xsss');
        setParentGroups(response.data)
      // setModalType(response.data.erp || 'tally')
      return response.data; 
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  useEffect(() => {
    
  
    fetchData();
    fetchParent()
  }, []);
  

  return (
    <div
      id="add_group"
      className={`modal fade ${visible ? "show d-block" : ""}`}
      role="dialog"
      aria-hidden={!visible}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Create Groups</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onCancel}
            />
          </div>
          <div className="modal-body p-4">
            <form onSubmit={handleSubmit}>
              {/* <div className="form-group mb-3">
                <label htmlFor="groupType">Group Type</label>
                <Select
                  value={modalType}
                  options={[
                    { value: "general", label: "General Group" },
                    { value: "tally", label: "Tally Group" },
                    { value: "zoho", label: "Zoho Group" },
                  ]}
                  required
                  onChange={(selectedOption) => {
                    setModalType(selectedOption);
                  }}
                />
              </div> */}

              {/* Common UI elements for Tally and Zoho groups */}
              {(modalType === "tally" || modalType  === "zoho") && (
                <>
                  <div className="form-group mb-3">
                    <label htmlFor="nameOfGroup">Name of Group</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nameOfGroup"
                      name="nameOfGroup"
                      placeholder="Enter group name"
                      value={formData.nameOfGroup}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                {modalType === 'tally' &&(
                <div className="form-group mb-3">
                <label htmlFor="isPrimaryGroup">Is it Primary Group</label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="isPrimaryGroup"
                    name="isPrimaryGroup"
                    checked={formData.isPrimaryGroup}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
                )}


                  <div className="form-group mb-3">
                    <label htmlFor="parentGroup">
                      {formData.isPrimaryGroup ? "Head" : "Parent Group"}
                    </label>
                    {parentGroups && (
                    <Select
                      value={formData.parentGroup}
                      options={parentGroups}
                      placeholder={formData.isPrimaryGroup ? "Select a head" : "Select a primary group"}
                      onChange={(selectedOption) => {
                        setFormData({
                          ...formData,
                          parentGroup: selectedOption
                        });
                      }}
                    />
                  )}
                  </div>
                </>
              )}

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

GroupCreationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default GroupCreationModal;