/* eslint-disable */
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import CustomInput from "../../../_components/inputField/custominput";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { scheduleTypes, weekDays } from "../constant";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import RecurringTable from "./tableEditRecurring";

const AddEditRecurringModal = ({
  id,
  handleSubmit,
  recurringInvoiceSubmit,
  control,
  errors,
  addError,
  handleDateChange,
  datePickerRef,
  selectRefs,
  customer,
  handleSelectChange,
  customerDetail,
  scheduleData,
  setScheduleData,
  // day,
  handleCheckBox,
  handleItemChange,
  // ledgerData,
  // setLedgerData,
  itemsError,
  setIsChanged,
  handleModalClose,
  isChanged,
  loading,
  handleRepeatSelectChange,
  setEditData,
  editable,
  setEditable,
  expenseList,
  ledgerListLoading,
  data,
  setData,
  handleCellChange,
  handleSelectCellChange,
  handleDoubleClick,
  footerData,
  setFooterData,
  editModal,
  recurringInvoiceData,
  // setRecurringInvoiceData,
  handleChange,
  setAddLedgerData,
  addLedgerData,
  apiError,
  setApiError,
  setIsModalVisible,
  isModalVisible,
  postLedgerLoading,
  addLedgerSubmit,
}) => {
  const onModalOpen = () => {
    $("#add_ledger").on("shown.bs.modal", () => {
      console.log("Oprn>>");
      setIsModalVisible(true);
      setAddLedgerData({ title: "", type: "", description: "" });
      setApiError({});
    });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setIsModalVisible(false);
      setAddLedgerData({ title: "", type: "", description: "" });
      setApiError({});
    });
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(recurringInvoiceSubmit)}>
        <div className="col-md-12">
          <div className="form-group-item border-0 mb-0">
            <div className="row align-item-center">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    Customer<span className="text-danger">*</span>
                  </label>
                  <Controller
                    name={"customer"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        ref={(el) => (selectRefs.current["customer"] = el)}
                        options={customer?.map((option) => ({
                          value: option.id,
                          label: option.name,
                        }))}
                        onChange={(selectedOption) =>
                          handleSelectChange(
                            "customer",
                            selectedOption,
                            field.onChange(selectedOption)
                          )
                        }
                      />
                    )}
                  />
                  {errors?.customer?.message && (
                    <p className="text-danger">{errors?.customer?.message}</p>
                  )}
                  {addError?.customer && (
                    <p className="text-danger">{addError?.customer}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    Start Date<span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="start_date"
                    control={control}
                    render={({ field }) => (
                      <div className="cal-icon cal-icon-info">
                        <DatePicker
                          {...field}
                          className="datetimepicker form-control"
                          selected={field?.value}
                          onChange={(date) =>
                            handleDateChange(
                              "start_date",
                              date,
                              field?.onChange(date)
                            )
                          }
                          id="start_date"
                          ref={datePickerRef?.start_date}
                        />
                      </div>
                    )}
                  />
                  {errors?.start_date?.message && (
                    <p className="text-danger">{errors?.start_date?.message}</p>
                  )}
                  {addError?.start_date && (
                    <p className="text-danger">{addError?.start_date}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    End Date<span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="end_date"
                    control={control}
                    render={({ field }) => (
                      <div className="cal-icon cal-icon-info">
                        <DatePicker
                          {...field}
                          className="datetimepicker form-control"
                          selected={field?.value}
                          onChange={(date) =>
                            handleDateChange(
                              "end_date",
                              date,
                              field?.onChange(date)
                            )
                          }
                          id="end_date"
                          ref={datePickerRef?.end_date}
                        />
                      </div>
                    )}
                  />
                  {errors?.end_date?.message && (
                    <p className="text-danger">{errors?.end_date?.message}</p>
                  )}
                  {addError?.end_date && (
                    <p className="text-danger">{addError?.end_date}</p>
                  )}
                </div>
              </div>

              <>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <CustomInput
                      id="description"
                      type="text"
                      value={recurringInvoiceData?.description}
                      onChange={handleChange}
                      placeholder="Nature of Invoice"
                      label="Nature of Invoice"
                    />
                    {addError?.description && (
                      <p className="text-danger">{addError?.description}</p>
                    )}
                  </div>
                </div>
                {customerDetail?.customer_code && (
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-block mb-3">
                      <CustomInput
                        id="tax_ledger"
                        type="text"
                        value={customerDetail?.customer_code}
                        placeholder="Customer Code"
                        label="Customer Code"
                        disabled={true}
                      />
                    </div>
                  </div>
                )}
                {customerDetail?.gstin_number && (
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-block mb-3">
                      <CustomInput
                        id="tax_ledger"
                        type="text"
                        value={customerDetail?.gstin_number}
                        placeholder="GSTIN"
                        label="GSTIN"
                        disabled={true}
                      />
                    </div>
                  </div>
                )}
                {customerDetail?.location && (
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-block mb-3">
                      <CustomInput
                        id="location"
                        type="text"
                        value={customerDetail?.location}
                        placeholder="Customer Location"
                        label="Customer Location"
                        disabled={true}
                      />
                    </div>
                  </div>
                )}
              </>

              <div className="col-lg-12 col-md-6 col-sm-12 intervalDivs">
                <div className="row">
                  <div className="col-md-4">
                    <div className="input-block mb-3">
                      <div style={{ marginBottom: "1rem" }}>
                        <label>
                          Interval<span className="text-danger">*</span>
                        </label>
                        <Select
                          style={{
                            width: "100%",
                            padding: "0.5rem",
                            fontSize: "0.875rem",
                            border: "1px solid #D1D5DB",
                            borderRadius: "0.375rem",
                          }}
                          required
                          value={scheduleData?.schedule_type}
                          // ref={(el) => (selectRefs.current["day"] = el)}
                          options={scheduleTypes}
                          onChange={(selectedOption) => {
                            if (selectedOption?.value === "monthly") {
                              setScheduleData((prevState) => ({
                                ...prevState,
                                dayOfMonth: true,
                                schedule_type: selectedOption,
                              }));
                              setEditData((prevState) => ({
                                ...prevState,
                                dayOfMonth: true,
                                schedule_type: selectedOption?.value,
                              }));
                            } else {
                              setEditData((prevState) => ({
                                ...prevState,
                                schedule_type: selectedOption?.value,
                                dayOfMonth: false,
                                nthWeekday: false,
                                quarterly: {
                                  month_of_quarter: null, // 1st month of the quarter

                                  day_of_month: null, // 15th day of the month

                                  repeat_every: null, // Every quarter
                                },
                                weekly: {
                                  days_of_week: [], // 1 = Monday, 5 = Friday

                                  repeat_every: null, // Every week
                                },
                                yearly: {
                                  month_of_year: null, // January

                                  day_of_month: null, // 15th day of the month

                                  repeat_every: null, // Every year
                                },
                                monthly: {
                                  day_of_month: null, // 15th day of the month

                                  repeat_every: null, // Every month
                                },
                              }));
                              setScheduleData((prevState) => ({
                                ...prevState,
                                schedule_type: selectedOption,
                                dayOfMonth: false,
                                nthWeekday: false,
                                quarterly: {
                                  month_of_quarter: null, // 1st month of the quarter

                                  day_of_month: null, // 15th day of the month

                                  repeat_every: null, // Every quarter
                                },
                                weekly: {
                                  days_of_week: [], // 1 = Monday, 5 = Friday

                                  repeat_every: null, // Every week
                                },
                                yearly: {
                                  month_of_year: null, // January

                                  day_of_month: null, // 15th day of the month

                                  repeat_every: null, // Every year
                                },
                                monthly: {
                                  day_of_month: null, // 15th day of the month

                                  repeat_every: null, // Every month
                                },
                              }));
                            }

                            id !== "addrecurring_invoice" && setIsChanged(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {scheduleData?.schedule_type?.value === "weekly" && (
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-9">
                          <label>Select Days</label>
                          <div className="dayrows">
                            {weekDays.map((option) => (
                              <div key={option?.value}>
                                <input
                                  type="checkbox"
                                  id={option?.label}
                                  checked={
                                    scheduleData?.weekly &&
                                    scheduleData?.weekly?.days_of_week?.includes(
                                      option?.value
                                    )
                                  }
                                  required={
                                    scheduleData?.weekly?.days_of_week
                                      ?.length === 0
                                  }
                                  onChange={(e) =>
                                    handleCheckBox(e, option?.value)
                                  }
                                />
                                <label
                                  htmlFor={option?.label}
                                  style={{ fontSize: "0.875rem" }}
                                >
                                  {option?.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-3 customDivSelect">
                          <label>
                            Repeat every<span className="text-danger">*</span>
                          </label>
                          <Select
                            style={{
                              width: "100%",
                              padding: "0.5rem",
                              fontSize: "0.875rem",
                              border: "1px solid #D1D5DB",
                              borderRadius: "0.375rem",
                            }}
                            required
                            id="repeat_every"
                            value={
                              scheduleData["weekly"]
                                ? scheduleData["weekly"]?.repeat_every
                                : ""
                            }
                            // ref={(el) => (selectRefs.current["day"] = el)}
                            options={[...Array(10)]?.map((option, index) => ({
                              value: index + 1,
                              label: `${index + 1} weeks`,
                            }))}
                            onChange={(selectedOption) =>
                              handleRepeatSelectChange(
                                selectedOption,
                                "weekly",
                                "repeat_every"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {scheduleData?.schedule_type?.value === "monthly" && (
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-3 customDivSelect">
                          <label htmlFor="dayOfMonth">
                            <input
                              type="radio"
                              id="dayOfMonth"
                              name="monthlyOption"
                              required
                              checked={scheduleData?.dayOfMonth === true}
                              onChange={(e) => {
                                setScheduleData((prevState) => ({
                                  ...prevState,
                                  [e.target.id]:
                                    e.target.value == "on" ? true : false,
                                  nthWeekday: false,
                                  monthly: {
                                    ...scheduleData["monthly"],
                                    nth_day_of_week: null,
                                    day_of_week: null,
                                    repeat_every: null,
                                  },
                                }));
                                setEditData((prevState) => ({
                                  ...prevState,
                                  [e.target.id]:
                                    e.target.value == "on" ? true : false,
                                  nthWeekday: false,
                                  monthly: {
                                    ...scheduleData["monthly"],
                                    nth_day_of_week: null,
                                    day_of_week: null,
                                    repeat_every: null,
                                  },
                                }));
                              }}
                            />{" "}
                            Day of month
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            style={{
                              width: "100%",
                              padding: "0.5rem",
                              fontSize: "0.875rem",
                              border: "1px solid #D1D5DB",
                              borderRadius: "0.375rem",
                            }}
                            required
                            id="day_of_month"
                            value={
                              scheduleData["monthly"]
                                ? scheduleData["monthly"]?.day_of_month
                                : ""
                            }
                            options={[...Array(31)]?.map((option, index) => ({
                              value: index + 1,
                              label: `${index + 1}`,
                            }))}
                            isDisabled={!scheduleData?.dayOfMonth}
                            onChange={(selectedOption) =>
                              handleRepeatSelectChange(
                                selectedOption,
                                "monthly",
                                "day_of_month"
                              )
                            }
                          />
                        </div>

                        <>
                          <div className="col-md-3 customDivSelect">
                            <label htmlFor="nthWeekday">
                              <input
                                type="radio"
                                id="nthWeekday"
                                name="monthlyOption"
                                checked={scheduleData?.nthWeekday === true}
                                onChange={(e) => {
                                  setScheduleData((prevState) => ({
                                    ...prevState,
                                    [e.target.id]:
                                      e.target.value === "on" ? true : false,
                                    dayOfMonth: false,
                                    monthly: {
                                      ...scheduleData["monthly"],
                                      day_of_month: null,
                                      repeat_every: null,
                                    },
                                  }));
                                  setEditData((prevState) => ({
                                    ...prevState,
                                    [e.target.id]:
                                      e.target.value == "on" ? true : false,
                                    dayOfMonth: false,
                                    monthly: {
                                      ...scheduleData["monthly"],
                                      day_of_month: null,
                                      repeat_every: null,
                                    },
                                  }));
                                }}
                              />{" "}
                              nth weekday
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              style={{
                                width: "100%",
                                padding: "0.5rem",
                                fontSize: "0.875rem",
                                border: "1px solid #D1D5DB",
                                borderRadius: "0.375rem",
                              }}
                              required
                              isDisabled={!scheduleData?.nthWeekday}
                              id="nth_day_of_week"
                              value={
                                scheduleData["monthly"]
                                  ? scheduleData["monthly"]?.nth_day_of_week
                                  : ""
                              }
                              options={[
                                { label: "1st", value: 1 },
                                { label: "2nd", value: 2 },
                                { label: "3rd", value: 3 },
                                { label: "4th", value: 4 },
                                { label: "last", value: 5 },
                              ]?.map((option) => ({
                                value: option?.value,
                                label: `${option?.label}`,
                              }))}
                              onChange={(selectedOption) =>
                                handleRepeatSelectChange(
                                  selectedOption,
                                  "monthly",
                                  "nth_day_of_week"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-3 customDivSelect">
                            <label>
                              Day of week<span className="text-danger">*</span>
                            </label>
                            <Select
                              style={{
                                width: "100%",
                                padding: "0.5rem",
                                fontSize: "0.875rem",
                                border: "1px solid #D1D5DB",
                                borderRadius: "0.375rem",
                              }}
                              required
                              id="day_of_week"
                              value={
                                scheduleData["monthly"]
                                  ? scheduleData["monthly"]?.day_of_week
                                  : ""
                              }
                              isDisabled={!scheduleData?.nthWeekday}
                              // ref={(el) => (selectRefs.current["day"] = el)}
                              options={weekDays}
                              onChange={(selectedOption) =>
                                handleRepeatSelectChange(
                                  selectedOption,
                                  "monthly",
                                  "day_of_week"
                                )
                              }
                            />
                          </div>
                        </>
                        {/* )} */}
                        <div className="col-md-3 customDivSelect">
                          <label>
                            Repeat every<span className="text-danger">*</span>
                          </label>
                          <Select
                            style={{
                              width: "100%",
                              padding: "0.5rem",
                              fontSize: "0.875rem",
                              border: "1px solid #D1D5DB",
                              borderRadius: "0.375rem",
                            }}
                            required
                            isDisabled={
                              !scheduleData?.nthWeekday &&
                              !scheduleData?.dayOfMonth
                            }
                            id="repeat_every"
                            value={
                              scheduleData["monthly"]
                                ? scheduleData["monthly"]?.repeat_every
                                : ""
                            }
                            // ref={(el) => (selectRefs.current["day"] = el)}
                            options={[...Array(13)]?.map((option, index) => ({
                              value: index + 1,
                              label: `${index + 1} month`,
                            }))}
                            onChange={(selectedOption) =>
                              handleRepeatSelectChange(
                                selectedOption,
                                "monthly",
                                "repeat_every"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {scheduleData?.schedule_type?.value === "quarterly" && (
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-3 customDivSelect">
                          <label>
                            Month of Quarter
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            style={{
                              width: "100%",
                              padding: "0.5rem",
                              fontSize: "0.875rem",
                              border: "1px solid #D1D5DB",
                              borderRadius: "0.375rem",
                            }}
                            required
                            id="month_of_quarter"
                            value={
                              scheduleData["quarterly"]
                                ? scheduleData["quarterly"]?.month_of_quarter
                                : ""
                            }
                            // ref={(el) => (selectRefs.current["day"] = el)}
                            options={[
                              "1st month",
                              "2nd month",
                              "3rd month",
                            ]?.map((option, index) => ({
                              value: index + 1,
                              label: `${option}`,
                            }))}
                            onChange={(selectedOption) =>
                              handleRepeatSelectChange(
                                selectedOption,
                                "quarterly",
                                "month_of_quarter"
                              )
                            }
                          />
                        </div>
                        <div className="col-md-3 customDivSelect">
                          <label>
                            Day of Month<span className="text-danger">*</span>
                          </label>
                          <Select
                            style={{
                              width: "100%",
                              padding: "0.5rem",
                              fontSize: "0.875rem",
                              border: "1px solid #D1D5DB",
                              borderRadius: "0.375rem",
                            }}
                            id="day_of_month"
                            required
                            value={
                              scheduleData["quarterly"]
                                ? scheduleData["quarterly"]?.day_of_month
                                : ""
                            }
                            // ref={(el) => (selectRefs.current["day"] = el)}
                            options={[...Array(31)]?.map((option, index) => ({
                              value: index + 1,
                              label: `${index + 1}`,
                            }))}
                            onChange={(selectedOption) =>
                              handleRepeatSelectChange(
                                selectedOption,
                                "quarterly",
                                "day_of_month"
                              )
                            }
                          />
                        </div>
                        <div className="col-md-3 customDivSelect">
                          <label>
                            Repeat Every<span className="text-danger">*</span>
                          </label>
                          <Select
                            style={{
                              width: "100%",
                              padding: "0.5rem",
                              fontSize: "0.875rem",
                              border: "1px solid #D1D5DB",
                              borderRadius: "0.375rem",
                            }}
                            required
                            id="repeat_every"
                            value={
                              scheduleData["quarterly"]
                                ? scheduleData["quarterly"]?.repeat_every
                                : ""
                            }
                            // ref={(el) => (selectRefs.current["day"] = el)}
                            options={[...Array(5)]?.map((option, index) => ({
                              value: index + 1,
                              label: `${index + 1} quarter`,
                            }))}
                            onChange={(selectedOption) =>
                              handleRepeatSelectChange(
                                selectedOption,
                                "quarterly",
                                "repeat_every"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {scheduleData?.schedule_type?.value === "yearly" && (
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-3 customDivSelect">
                          <label>
                            Month of Year<span className="text-danger">*</span>
                          </label>
                          <Select
                            style={{
                              width: "100%",
                              padding: "0.5rem",
                              fontSize: "0.875rem",
                              border: "1px solid #D1D5DB",
                              borderRadius: "0.375rem",
                            }}
                            required
                            id="month_of_year"
                            value={
                              scheduleData["yearly"]
                                ? scheduleData["yearly"]?.month_of_year
                                : ""
                            }
                            // ref={(el) => (selectRefs.current["day"] = el)}
                            options={[...Array(12)]?.map((option, index) => ({
                              value: index + 1,
                              label: `${index + 1}`,
                            }))}
                            onChange={(selectedOption) =>
                              handleRepeatSelectChange(
                                selectedOption,
                                "yearly",
                                "month_of_year"
                              )
                            }
                          />
                        </div>
                        <div className="col-md-3 customDivSelect">
                          <label>
                            Day of Month<span className="text-danger">*</span>
                          </label>
                          <Select
                            style={{
                              width: "100%",
                              padding: "0.5rem",
                              fontSize: "0.875rem",
                              border: "1px solid #D1D5DB",
                              borderRadius: "0.375rem",
                            }}
                            required
                            id="day_of_month"
                            value={
                              scheduleData["yearly"]
                                ? scheduleData["yearly"]?.day_of_month
                                : ""
                            }
                            // ref={(el) => (selectRefs.current["day"] = el)}
                            options={[...Array(31)]?.map((option, index) => ({
                              value: index + 1,
                              label: `${index + 1}`,
                            }))}
                            onChange={(selectedOption) =>
                              handleRepeatSelectChange(
                                selectedOption,
                                "yearly",
                                "day_of_month"
                              )
                            }
                          />
                        </div>
                        <div className="col-md-3 customDivSelect">
                          <label>
                            Repeat Every<span className="text-danger">*</span>
                          </label>
                          <Select
                            style={{
                              width: "100%",
                              padding: "0.5rem",
                              fontSize: "0.875rem",
                              border: "1px solid #D1D5DB",
                              borderRadius: "0.375rem",
                            }}
                            required
                            id="repeat_every"
                            value={
                              scheduleData["yearly"]
                                ? scheduleData["yearly"]?.repeat_every
                                : ""
                            }
                            // ref={(el) => (selectRefs.current["day"] = el)}
                            options={[...Array(52)]?.map((option, index) => ({
                              value: index + 1,
                              label: `${index + 1} year`,
                            }))}
                            onChange={(selectedOption) =>
                              handleRepeatSelectChange(
                                selectedOption,
                                "yearly",
                                "repeat_every"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {errors?.interval?.message && (
                    <p className="text-danger">{errors?.interval?.message}</p>
                  )}
                  {addError?.interval && (
                    <p className="text-danger">{addError?.interval}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="addInvoiceTable">
            <h6>Invoice Intent</h6>
            <RecurringTable
              id={id === "addrecurring_invoice" ? "addrecurring_invoice" : ""}
              // ledgerData={ledgerData}
              handleItemChange={handleItemChange}
              itemsError={itemsError}
              setIsChanged={id !== "addrecurring_invoice" && setIsChanged}
              // setLedgerData={setLedgerData}
              editable={editable}
              setEditable={setEditable}
              expenseList={expenseList}
              ledgerListLoading={ledgerListLoading}
              data={data}
              setData={setData}
              handleCellChange={handleCellChange}
              handleSelectCellChange={handleSelectCellChange}
              handleDoubleClick={handleDoubleClick}
              footerData={footerData}
              setFooterData={setFooterData}
              editModal={editModal}
              setAddLedgerData={setAddLedgerData}
              addLedgerData={addLedgerData}
              apiError={apiError}
              setApiError={setApiError}
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
              postLedgerLoading={postLedgerLoading}
              recurringInvoiceSubmit={recurringInvoiceSubmit}
              handleSubmit={handleSubmit}
              handleModalClose={handleModalClose}
              addLedgerSubmit={addLedgerSubmit}
            />
          </div>
          {id === "addrecurring_invoice" ? (
            <>
              <Link to="/recurring-invoices">
                <button type="reset" className="btn btn-primary cancel me-2">
                  Cancel
                </button>
              </Link>
              <button type="submit" className="btn btn-primary">
                {loading ? <Spin /> : "Save"}
              </button>
            </>
          ) : (
            <>
              <Link to="/recurring-invoices">
                <button type="reset" className="btn btn-primary cancel me-2">
                  Cancel
                </button>
              </Link>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isChanged || loading}
              >
                {loading ? <Spin /> : "Save"}
              </button>
            </>
          )}
        </div>
      </form>
    </>
  );
};

AddEditRecurringModal.propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  recurringInvoiceSubmit: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  addError: PropTypes.object,
  handleDateChange: PropTypes.func.isRequired,
  datePickerRef: PropTypes.object.isRequired,
  recurringInvoiceData: PropTypes.object.isRequired,
  error: PropTypes.object,
  selectRefs: PropTypes.object.isRequired,
  customer: PropTypes.array,
  handleSelectChange: PropTypes.func.isRequired,
  customerDetail: PropTypes.object.isRequired,
  scheduleData: PropTypes.string.isRequired,
  setScheduleData: PropTypes.func.isRequired,
  day: PropTypes.array.isRequired,
  handleCheckBox: PropTypes.func.isRequired,
  handleItemChange: PropTypes.func.isRequired,
  ledgerData: PropTypes.array.isRequired,
  setLedgerData: PropTypes.func.isRequired,
  itemsError: PropTypes.array.isRequired,
  setIsChanged: PropTypes.func,
  handleModalClose: PropTypes.func,
  isChanged: PropTypes.bool,
  loading: PropTypes.bool,
  handleRepeatSelectChange: PropTypes.func.isRequired,
  setEditData: PropTypes.func.isRequired,
  editable: PropTypes.object,
  setEditable: PropTypes.func,
  expenseList: PropTypes.array,
  ledgerListLoading: PropTypes.bool,
  data: PropTypes.array,
  setData: PropTypes.func,
  handleCellChange: PropTypes.func,
  handleSelectCellChange: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  footerData: PropTypes.object,
  setFooterData: PropTypes.func,
  editModal: PropTypes.bool,
  setRecurringInvoiceData: PropTypes.func,
  setAddLedgerData: PropTypes.func,
  addLedgerData: PropTypes.object,
  apiError: PropTypes.object,
  setApiError: PropTypes.func,
  setIsModalVisible: PropTypes.func,
  isModalVisible: PropTypes.bool,
  postLedgerLoading: PropTypes.bool,
};

export default AddEditRecurringModal;
