export const RECURRINGINVOICE_CREATE_REQUEST =
  "RECURRINGINVOICE_CREATE_REQUEST";
export const RECURRINGINVOICE_CREATE_SUCCESS =
  "RECURRINGINVOICE_CREATE_SUCCESS";
export const RECURRINGINVOICE_GET_INVOICELIST_PAGE =
  " RECURRINGINVOICE_GET_INVOICELIST_PAGE";

export const RECURRINGINVOICE_CREATE_FAIL = "RECURRINGINVOICE_CREATE_FAIL";
export const RECURRINGINVOICE_CREATE_RESET = "RECURRINGINVOICE_CREATE_RESET";
export const RECURRINGINVOICE_LIST_REQUEST = "RECURRINGINVOICE_LIST_REQUEST";
export const RECURRINGINVOICE_LIST_SUCCESS = "RECURRINGINVOICE_LIST_SUCCESS";
export const RECURRINGINVOICE_LIST_FAIL = "RECURRINGINVOICE_LIST_FAIL";
export const RECURRINGINVOICE_EDIT_DETAIL_REQUEST =
  "RECURRINGINVOICE_EDIT_DETAIL_REQUEST";
export const RECURRINGINVOICE_EDIT_DETAIL_SUCCESS =
  "RECURRINGINVOICE_EDIT_DETAIL_SUCCESS";
export const RECURRINGINVOICE_EDIT_DETAIL_FAIL =
  "RECURRINGINVOICE_EDIT_DETAIL_FAIL";
export const RECURRINGINVOICE_EDIT_REQUEST = "RECURRINGINVOICE_EDIT_REQUEST";
export const RECURRINGINVOICE_EDIT_SUCCESS = "RECURRINGINVOICE_EDIT_SUCCESS";
export const RECURRINGINVOICE_EDIT_FAIL = "RECURRINGINVOICE_EDIT_FAIL";
export const RECURRINGINVOICE_EDIT_RESET = "RECURRINGINVOICE_EDIT_RESET";
