/* eslint-disable */ import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { message, Spin } from "antd";
import { updateUserInfo, verifyOtp } from "../../redux/actions/UserAction";
import { Modal } from "bootstrap";

const OTP = ({ show, onClose, phone, setCurrentStep }) => {
  const [otpValue, setOtpValue] = useState("");
  const dispatch = useDispatch();
  const userOTP = useSelector((state) => state.userOTP);
  const { payload, success, loading, error } = userOTP;
  const [otp, setOtp] = useState({
    field_1: "",
    field_2: "",
    field_3: "",
    field_4: "",
    field_5: "",
  });
  const [apiError, setApiError] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (otpValue.length === 5) {
      const data = { otp: otpValue, phone: phone };
      dispatch(verifyOtp(data));
    } else {
      messageApi.open({
        type: "error",
        content: "Please enter a valid 5-digit OTP",
        duration: 5, 
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = value.replace(/[^0-9]/g, "");
    setOtp((prevState) => ({ ...prevState, [name]: newValue }));
    setApiError("");
  };
console.log("error>>>>>",apiError);
  const inputfocus = (element) => {
    if (element.key === "Delete" || element.key === "Backspace") {
      const next = element.target.tabIndex - 2;
      if (next > -1) {
        element.target.form.elements[next].focus();
      }
    } else {
      const next = element.target.tabIndex;
      if (next < 5 && element.target.value !== "") {
        element.target.form.elements[next].focus();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      otp.field_1 !== "" &&
      otp.field_2 !== "" &&
      otp.field_3 !== "" &&
      otp.field_4 !== "" &&
      otp.field_5 !== ""
    ) {
      const fullOtp = `${otp.field_1}${otp.field_2}${otp.field_3}${otp.field_4}${otp.field_5}`;
      const data = { otp: fullOtp, phone: phone };
      dispatch(verifyOtp(data));
    } else if (otp.field_1 === "") {
      document.getElementById("field_1").focus();
    } else if (otp.field_2 === "") {
      document.getElementById("field_2").focus();
    } else if (otp.field_3 === "") {
      document.getElementById("field_3").focus();
    } else if (otp.field_4 === "") {
      document.getElementById("field_4").focus();
    } else if (otp.field_5 === "") {
      document.getElementById("field_5").focus();
    }
  };

  useEffect(() => {
    if (otp.field_1 === "") {
      document.getElementById("field_1").focus();
    }
  }, [otp.field_1]);

  useEffect(() => {
    if (success) {
      const otpModal = Modal.getInstance(document.getElementById("otp-modal"));
      if (otpModal) {
        otpModal.hide();
      }
      onClose();
      dispatch(updateUserInfo(payload));
      setCurrentStep(1);
    }
    if (error) {
      console.log("**error>>>>>",error?.detail);
      setApiError(error?.detail);
    }
  }, [success, payload, messageApi,error]);

  return (
    <>
      {contextHolder}
      <div
        id="otp-modal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="px-3">
                {/* <div className="mb-3">
                  <label className="form-label">Mobile Verification</label>
                  <input
                    className="form-control"
                    type="number"
                    value={otpValue}
                    onChange={(e) => setOtpValue(e.target.value)}
                    maxLength={6}
                  />
                </div> */}
                <div
                  className="otp-wrap"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <input
                    type="text"
                    maxLength={1}
                    value={otp.field_1}
                    id="field_1"
                    name="field_1"
                    onChange={(e) => handleChange(e)}
                    tabIndex="1"
                    onKeyUp={(e) => inputfocus(e)}
                    style={{
                      width: "50px",
                      height: "50px",
                      fontSize: "20px",
                      textAlign: "center",
                      marginRight: "10px",
                    }}
                  />
                  <input
                    type="text"
                    maxLength={1}
                    value={otp.field_2}
                    id="field_2"
                    name="field_2"
                    onChange={(e) => handleChange(e)}
                    tabIndex="2"
                    onKeyUp={(e) => inputfocus(e)}
                    style={{
                      width: "50px",
                      height: "50px",
                      fontSize: "20px",
                      textAlign: "center",
                      marginRight: "10px",
                    }}
                  />
                  <input
                    type="text"
                    maxLength={1}
                    value={otp.field_3}
                    id="field_3"
                    name="field_3"
                    onChange={(e) => handleChange(e)}
                    tabIndex="3"
                    onKeyUp={(e) => inputfocus(e)}
                    style={{
                      width: "50px",
                      height: "50px",
                      fontSize: "20px",
                      textAlign: "center",
                      marginRight: "10px",
                    }}
                  />
                  <input
                    type="text"
                    maxLength={1}
                    value={otp.field_4}
                    id="field_4"
                    name="field_4"
                    onChange={(e) => handleChange(e)}
                    tabIndex="4"
                    onKeyUp={(e) => inputfocus(e)}
                    style={{
                      width: "50px",
                      height: "50px",
                      fontSize: "20px",
                      textAlign: "center",
                      marginRight: "10px",
                    }}
                  />
                  <input
                    type="text"
                    maxLength={1}
                    value={otp.field_5}
                    id="field_5"
                    name="field_5"
                    onChange={(e) => handleChange(e)}
                    tabIndex="5"
                    onKeyUp={(e) => inputfocus(e)}
                    style={{
                      width: "50px",
                      height: "50px",
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  />
                </div>
                {apiError && <p className="text-danger">{apiError}</p>}
                <br />
                <div className="mb-2 text-center">
                  <button
                    className="btn rounded-pill btn-primary me-1"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <Spin /> : "Verify"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

OTP.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default OTP;
