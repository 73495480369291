import axios from "axios";
import store from "./store";
import { setError } from "./actions/commonApiAction";

// export let mode = "prod";
export let mode = "staging";

let baseValues = {
  baseProtocol: {
    prod: "https://",
    staging: "https://",
  },

  baseHost: {
    prod: "stylehr.in/api/",
    // staging: "3.109.217.81:8000/api",
    // staging:"app.glancewise.com/api"
    staging: "api.tideiq.com/api",
  },
};

let baseProtocol = baseValues.baseProtocol[mode];
let baseHost = baseValues.baseHost[mode];
export const baseURL = baseProtocol + baseHost;

const HTTP = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

HTTP.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // Check for 401 Unauthorized errors and handle token refresh logic
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refresh = localStorage.getItem("refresh");
        if (refresh) {
          const refreshToken = JSON.parse(refresh);
          if (refreshToken) {
            const { data } = await HTTP.post("/users/token/refresh/", {
              refresh: refreshToken,
            });
            const { access } = data;
            localStorage.setItem("userInfo", JSON.stringify(access));

            originalRequest.headers.Authorization = `Bearer ${access}`;
            return HTTP(originalRequest);
          }
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    }

    // Check for 403 Forbidden errors and handle 'MAX_LIMIT_REACHED' error code
    // Check for 403 Forbidden errors and handle specific error codes
    if (error.response.status === 403) {
      const errorCode = error.response.data?.error_code;
      if (errorCode === 'MAX_LIMIT_REACHED' || errorCode === 'NO_ACTIVE_PLAN') {
        let message = errorCode === 'MAX_LIMIT_REACHED'
          ? 'Maximum limit reached. Please upgrade your plan.'
          : 'No active plan. Please upgrade.';
        store.dispatch(setError(message));
      }
    }
    if(error.response.status === 500){
      let message ='An unexpected error occurred. Please try again later.'
      store.dispatch(setError(message));
    }

    return Promise.reject(error); // Reject the promise to allow further error handling
  }
);

export { HTTP };
