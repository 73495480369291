/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "../../_components/antd.css";
import CustomerList from "./customerlist";
import VendorList from "./vendorlist";
import LedgerList from "./ledgerlist";

const chartsOfAccount = () => {
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState();

  $(document).ready(function () {
    $('a[data-bs-toggle="tab"]').on("shown.bs.tab", function (e) {
      var targetTab = $(e.target).attr("href");
      localStorage.setItem("listCurrentTab", targetTab);
      setActiveTab(targetTab);
    });

    var lastTab = localStorage.getItem("listCurrentTab");
    if (lastTab) {
      $('a[href="' + lastTab + '"]').tab("show");
      setActiveTab(lastTab);
    }
  });

  useEffect(() => {
    let previouslyStoredTab = localStorage.getItem("listCurrentTab");
    if (location?.state && location?.state?.tabName) {
      setActiveTab(`#${location?.state?.tabName}`);
      localStorage.setItem("listCurrentTab", `#${location?.state?.tabName}`);
      history?.replace({ state: null });
    } else {
      previouslyStoredTab
        ? setActiveTab(previouslyStoredTab)
        : setActiveTab("#ledgertab");
    }
  }, [location?.state]);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
              <div className="col">
              <ul className="breadcrumb">
                <li className="breadcrumb-item" key={"setting"}>
                  <Link to="/settings">
                    Settings
                  </Link>
                </li>
                <li className="breadcrumb-item active" key={"chart-of-account"}>Chart of Accounts</li>
              </ul>
            </div>
              </div>
            </div>
            {/* Page Header */}

            <div className="invoices-tabs-card">
              <div className="invoices-main-tabs">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="invoices-tabs">
                      <ul className="nav nav-tabs">
                        <li className="nav-item" key={"ledger"}>
                          <Link
                            className="nav-link active"
                            to="#ledgertab"
                            data-bs-toggle="tab"
                          >
                            Ledger List
                          </Link>
                        </li>
                        <li className="nav-item" key={"customerlist"}>
                          <Link
                            className="nav-link"
                            to="#customerlist"
                            data-bs-toggle="tab"
                          >
                            Customer List
                          </Link>
                        </li>
                        <li className="nav-item" key={"vendorlist"}>
                          <Link
                            className="nav-link"
                            to="#vendorlist"
                            data-bs-toggle="tab"
                          >
                            Vendor List
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane show active" id="ledgertab">
                          <LedgerList activeTab={activeTab} />
                        </div>
                        <div className="tab-pane"id="customerlist">
                          <CustomerList activeTab={activeTab}/>
                        </div>
                        <div className="tab-pane" id="vendorlist">
                          <VendorList activeTab={activeTab}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default chartsOfAccount;
