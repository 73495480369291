import {
  BANKLISTSET_PAGE,
  BANK_ADD_FAIL,
  BANK_ADD_REQUEST,
  BANK_ADD_RESET,
  BANK_ADD_SUCCESS,
  BANK_EDIT_DETAIL_FAIL,
  BANK_EDIT_DETAIL_REQUEST,
  BANK_EDIT_DETAIL_SUCCESS,
  BANK_EDIT_FAIL,
  BANK_EDIT_REQUEST,
  BANK_EDIT_RESET,
  BANK_EDIT_SUCCESS,
  BANK_GET_FAIL,
  BANK_GET_LIST_FAIL,
  BANK_GET_LIST_REQUEST,
  BANK_GET_LIST_SUCCESS,
  BANK_GET_REQUEST,
  BANK_GET_SUCCESS,
  BANK_STATEMENT_FAIL,
  BANK_STATEMENT_VIEW_REQUEST,
  BANK_STATEMENT_VIEW_SUCCESS,
  BANK_UPLOAD_FAIL,
  BANK_UPLOAD_REQUEST,
  BANK_UPLOAD_RESET,
  BANK_UPLOAD_SUCCESS,
  TRANSACTION_VIEW_FAIL,
  TRANSACTION_VIEW_REQUEST,
  TRANSACTION_VIEW_SUCCESS,
} from "../constants/BankConstant";

export const bankAddReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_ADD_REQUEST:
      return { loading: true };

    case BANK_ADD_SUCCESS:
      return { loading: false, success: true };

    case BANK_ADD_FAIL:
      return { loading: false, error: action.payload };
    case BANK_ADD_RESET:
      return {};
    default:
      return state;
  }
};

const initialState = {
  bankList: [],
  loading: false,
  error: null,
  page: 1,
  perPage: 10,
};

export const bankListReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANK_GET_REQUEST:
      return { ...state, loading: true, error: null };
    case BANK_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        bankList: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    case BANK_GET_FAIL:
      return { ...state, loading: false, error: action.payload };
    case BANKLISTSET_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export const bankEditDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_EDIT_DETAIL_REQUEST:
      return { loading: true };
    case BANK_EDIT_DETAIL_SUCCESS:
      return { loading: false, editDetail: action.payload };
    case BANK_EDIT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bankEditReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_EDIT_REQUEST:
      return { loading: true };

    case BANK_EDIT_SUCCESS:
      return { loading: false,success: true };

    case BANK_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case BANK_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const bankListApiReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_GET_LIST_REQUEST:
      return { loading: true };
    case BANK_GET_LIST_SUCCESS:
      return { loading: false, bankDropdownList: action.payload };
    case BANK_GET_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bankUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_UPLOAD_REQUEST:
      return { loading: true };
    case BANK_UPLOAD_SUCCESS:
      return { loading: false, success: true };
    case BANK_UPLOAD_FAIL:
      return { loading: false, error: action.payload };
    case BANK_UPLOAD_RESET:
      return {};
    default:
      return state;
  }
};

export const tranactionViewReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSACTION_VIEW_REQUEST:
      return { loading: true };

    case TRANSACTION_VIEW_SUCCESS:
      return {
        loading: false,
        transactionViewDetail: action.payload,
      };

    case TRANSACTION_VIEW_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bankStatementViewReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_STATEMENT_VIEW_REQUEST:
      return { loading: true };

    case BANK_STATEMENT_VIEW_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };

    case BANK_STATEMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
