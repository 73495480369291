import React from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";

const SubmitToErpModal = ({
  show,
  submitToErpError,
  handleSubmit,
  loading,
}) => {
  const submitToErpModal = (
    <div
      className="modal custom-modal fade submit-erp"
      id={"submit_to_erp"}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-body">
            <div className="form-header">
              <p>Are you sure want to submit to erp?</p>
            </div>
            {submitToErpError?.detail && (
              <>
              <p className="text-danger" style={{ textAlign: "center" }}>
                {submitToErpError?.detail}
              </p>
              <br/>
              </>
            )}
            
            <div className="modal-btn delete-action">
              <div className="row">
                <div className="col-6">
                  <button
                    className="w-100 btn btn-primary paid-continue-btn"
                    onClick={handleSubmit}
                  >
                    {loading ? <Spin /> : "Submit"}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="reset"
                    data-bs-dismiss="modal"
                    className="w-100 btn btn-primary paid-cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <>{submitToErpModal}</>;
};

export default SubmitToErpModal;

SubmitToErpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  submitToErpError: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};