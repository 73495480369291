/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import {
  clearButtonHoverStyle,
  clearButtonStyle,
  containerStyle,
} from "../sales/invoices/constant";

const APModuleHeader = ({
  filterData,
  setFilterData,
  userListData,
  handleSelectFilterChange,
  handleDateChange,
  permissions,
}) => {
  const statusOptions = [
    { id: "", text: "All" },
    { id: "Pending", text: "Pending" },
    { id: "ready to post", text: "Ready to Post" },
    { id: "Rejected", text: "Rejected" },
  ];

  const approvalStatusOptions = [
    { id: "", text: "All" },
    { id: "pending approval", text: "Pending Approval" },
    { id: "user approved", text: "User Approved" },
    { id: "rejected approval", text: "Rejected Approval" },
  ];

  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <>
      <div className="content-page-header">
        <div className="col">
          <h5>AP Module</h5>
        </div>
        {permissions?.apModule?.submitInvoice && (
          <div className="list-btn">
            <ul className="filter-list filterbutton">

              <li>
                <Link
                  className="btn btn-primary  me-2 "
                  to="/ap-module/add-invoice/"
                  role="button" 
                >
                  <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                  Create Invoice
                </Link>
              </li>
              &nbsp;
              <li>
                <Link
                  className="btn btn-primary  me-2 "
                  data-bs-toggle="modal"
                  to="#upload_invoice"
                  role="button" 
                >
                  <i className="fa fa-upload me-2" aria-hidden="true" /> 
                  Upload Invoice 
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="list-btn">
        <ul className="filter-list">
          <li>
            <div
              style={containerStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <DatePicker
                className="datetimepicker form-control"
                id="invoice_date"
                placeholderText="Date"
                selected={filterData?.invoice_date}
                onChange={(selectedDate) =>
                  handleDateChange(selectedDate, "invoice_date")
                }
              />
              {filterData?.invoice_date && (
                <button
                  type="button"
                  style={{
                    ...clearButtonStyle,
                    ...(isHovered ? clearButtonHoverStyle : {}),
                  }}
                  onClick={() => handleDateChange(null, "invoice_date")}
                  aria-label="Clear Date"
                >
                  <i className="fas fa-times" />
                </button>
              )}
            </div>
          </li>
          <li className="customDrpdwn">
            <Select
              options={[
                { value: "", label: "All" }, // Adding an empty option
                ...((userListData?.results?.length &&
                  userListData?.results?.map((option) => ({
                    value: option.id,
                    label: option.username,
                  }))) ||
                  []), // Fallback to an empty array if bankDropdownList is undefined
              ]}
              placeholder="Select User"
              value={filterData?.user}
              onChange={(selectedOption) =>
                handleSelectFilterChange(selectedOption, "user")
              }
            />
          </li>
          <li className="customDrpdwn">
            <Select
              options={statusOptions.map((option) => ({
                value: option.id,
                label: option.text,
              }))}
              value={filterData?.status}
              onChange={(selectedOption) =>
                handleSelectFilterChange(selectedOption, "status")
              }
              placeholder="Select Status"
            />
          </li>
          <li className="customDrpdwn">
            <Select
              options={approvalStatusOptions.map((option) => ({
                value: option.id,
                label: option.text,
              }))}
              value={filterData?.approval_status}
              onChange={(selectedOption) =>
                handleSelectFilterChange(selectedOption, "approval_status")
              }
              placeholder="Select Approval Status"
            />
          </li>
          {/* <li>
          <Select
            options={approverOptions.map((option) => ({
              value: option.id,
              label: option.text,
            }))}
            value={filterData?.approver}
            onChange={(selectedOption) =>
              setFilterData((prevState) => ({
                ...prevState,
                approver: selectedOption,
              }))
            }
            placeholder="Select Approver"
          />
        </li> */}
          {/* <li>
          <div style={{ marginLeft: "20px" }}>
            <Select
              placeholder="Post to ERP"
              // value={selectedUser}
              // options={userOptions.map((option) => ({
              //   value: option.id,
              //   label: option.text,
              // }))}
              // onChange={(selectedOption) => setSelectedUser(selectedOption)}
            />
          </div>
        </li> */}
        </ul>
      </div>
    </>
  );
};

APModuleHeader.propTypes = {
  filterData: PropTypes.object.isRequired,
  setFilterData: PropTypes.func.isRequired,
  userListData: PropTypes.object,
  handleSelectFilterChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default APModuleHeader;
